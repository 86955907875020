// import Image from 'next/image'
import React from 'react';

import { PDFViewer, Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';

import Logo from "../../../assets/images/wcro-2021-logo.png";
import urls from "../../../utils/urls";

const PDF = ({ pdfData }) => {

  const table = pdfData.table;
  const titles = pdfData.titles;
  const subtitles = pdfData.subtitles;
  const tables = pdfData.tables;
  const label = pdfData.label;
  const footer = pdfData.footer;
  let logo = urls.getS3Url(pdfData.image);
  console.log("Tables", tables);

  return (
      <PDFViewer style={styles.pdfWrap}>
        <Document>
          {tables ?
              tables.map((item, index) => {
                //console.log("Building item", item);
                let pager = "Page " + (index + 1).toString() + " / " + tables.length.toString();

                return (
                    <Page size="A4" wrap>
                      <Image style={styles.logoImage} src={logo}/>
                      <View style={styles.titleWrap}>
                        <Text style={[styles.titles, {fontSize: 24}]}>{label}</Text>
                        <Text>{"\n"}</Text>
                        {titles.map((title) =>
                            <Text style={[styles.titles, {fontSize: 12}]}>{title}</Text>
                        )}

                      </View>

                      <View style={styles.subtitleWrap}>


                        {subtitles ? subtitles.map((title) =>
                            <Text style={[styles.subtitles, {fontSize: 11}]}>{title}</Text>
                        ) : <Text></Text>}
                        <Text style={[styles.subtitles, {fontSize: 11, marginTop: 20}]}>{pager}</Text>

                      </View>
                      <View style={styles.tableWrap}>
                        <View style={styles.tableContentWrap}>
                          <View key={"headers"} style={styles.contentRow}>
                            {item.headers ? item.headers.map ? item.headers.map((td, i) => {

                              console.log("TD", td);
                                  return <Text key={i} style={styles.tableTitles}>{td}</Text>
                                }

                            ) : null : null}
                          </View>
                          {item.content ? item.content.map((tr, i) =>
                              <View key={i} style={styles.contentRow}>
                                {tr ? tr.map((td, j) => <Text key={j} style={styles.tableTitles}>{td}</Text>) : null}
                              </View>
                          ) : null}
                        </View>
                      </View>
                      {footer ?
                        <Text style={styles.footer}>{footer}</Text>
                      : null}
                    </Page>
                )
              })

              :
              <Page size="A4" wrap>
                <Image style={styles.logoImage} src={logo}/>
                <View style={styles.titleWrap}>
                  <Text style={[styles.titles, {fontSize: 24}]}>{label}</Text>
                  <Text>{"\n"}</Text>
                  {titles.map((title) =>
                      <Text style={[styles.titles, {fontSize: 12, marginBottom: '20px'}]}>{title}</Text>
                  )}

                </View>

                <View style={[styles.subtitleWrap, {marginTop: '20px'}]}>


                  {subtitles ? subtitles.map((title) =>
                      <Text style={[styles.subtitles, {fontSize: 11}]}>{title}</Text>
                  ) : <Text></Text>}
                </View>
                <View style={styles.tableWrap}>
                  <View style={styles.tableContentWrap}>
                    <View key={"headers"} style={styles.contentRow}>
                      {table.headers.map((td, i) =>
                          <Text key={i} style={styles.tableTitles}>{td}</Text>
                      )}
                    </View>
                    {table.content.map((tr, i) =>
                        <View key={i} style={styles.contentRow}>
                          {tr.map((td, j) => {
                          var font = { fontSize: 11 };

                          if (j === 0) {font = { fontSize: 22 } }
                          return (
                          <Text key={j} style={[styles.tableTitles, font]}>{td}</Text>
                          );
                          })}
                        </View>
                    )}
                  </View>
                </View>
                {footer ?
                  <Text style={styles.footer}>{footer}</Text>
                : null}
              </Page>
          }
        </Document>
      </PDFViewer>
  )
}

// Create styles
const styles = StyleSheet.create({
  container: {
    height: "100vh !important",
  },
  pdfWrap: {
    backgroundColor: "green",
    fontSize: 9,
    height: "100%",
    width: "100%",
  },
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
  },
  // LOGO
  logoImage: {
    position: "absolute",
    right: "25px",
    top: "25px",
    height: "50px",
    width: "50px",
    zIndex: 3,
  },

  // TITLES
  titles: {
    display: "flex",
    margin: "5px 0 5px 0",
    lineHeight: ".5px",
  },

  subtitles: {
    textAlign: 'left'
  },

  // TITLE WRAP
  titleWrap: {
    alignItems: "left",
    justifyContent: "center",
    color: "black",
    display: "flex",
    margin: "20px 0 0 50px",
    lineHeight: ".5px",
    width: "100%",
  },

  subtitleWrap: {
    alignItems: "left",
    justifyContent: "center",
    color: "black",
    display: "flex",
    margin: "0px 0 0 50px",
    width: "100%",
  },

  //TABLE
  tableWrap: {
    border: "1px solid black",
    margin: "20px 0 0 50px",
    width: "500px",
    fontSize: 9,
  },
  tableTitlesWrap: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  tableContentWrap: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  contentRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
  },
  tableTitles: {
    border: "1px solid black",
    flex: 1,
    padding: "5px",
  },
  tableContent: {
    border: "1px solid black",
    flex: 1,
    padding: "3px 5px",
  },

  // GRID
  gridContainer: {
    height: "841px",
    width: "594px",
    position: "absolute",
  },
  HG: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  VG: {
    display: "flex",
    flexDirection: "row",
    height: "100%",
    position: "absolute",
    width: "100%",
  },
  HGP: {
    border: "1px solid red",
    height: "5%",
  },
  VGP: {
    border: "1px solid red",
    height: "100%",
    width: "5%",
  },
  footer: {
    position: 'absolute',
    fontSize: 8,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center'
  },
});

export default PDF;
